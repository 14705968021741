import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import toast from '~/services/toast';
import RegraItem from '../RegraItem';
import TabelaItem from '../TabelaItem';

const useStyles = makeStyles(theme => ({
  quantityInput: {
    '& .MuiOutlinedInput-input': {
      padding: '5px 8px'
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main
      }
    },
    width: '100%'
  },
  quantityContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%'
  },
  quantidadeValor: {
    marginRight: theme.spacing(1)
  },
  editIcon: {
    padding: 2
  }
}));

const calculaValorInicial = data => {
  const updatedProduto = { ...data };

  if (updatedProduto.produtoRegras) {
    updatedProduto.produtoRegras = updatedProduto.produtoRegras.map(regra => {
      const quantidadeMinimaOriginal = regra.quantidadeMinima;
      const valorBrutoQntdMinima = regra.quantidadeMinima * regra.precoVenda;
      const descontoAplicado = regra.desconto || regra.valorDescontoRegra || 0;
      const porcentagemDescontoAplicado = regra.descontoPercentual || 0;
      const valorTotalQntdMinima =
        valorBrutoQntdMinima -
        descontoAplicado -
        valorBrutoQntdMinima * (porcentagemDescontoAplicado / 100);

      return {
        ...regra,
        quantidadeMinimaOriginal,
        valorBrutoQntdMinima,
        descontoAplicado,
        porcentagemDescontoAplicado,
        valorTotalQntdMinima,
        type: 'regra'
      };
    });
  }

  if (updatedProduto.tabelaRegraProduto) {
    updatedProduto.tabelaRegraProduto = updatedProduto.tabelaRegraProduto.map(tabela => {
      const valorBruto = tabela.quantidadeMinima * tabela.precoVenda;
      const valorLiquido = valorBruto * (1 + tabela.precoAjuste / 100);

      return {
        ...tabela,
        valorBruto,
        valorLiquido,
        type: 'tabela'
      };
    });
  }

  return updatedProduto;
};

const ListItemProduto = ({ data, callback }) => {
  const classes = useStyles();
  const [produto, setProduto] = useState(() => calculaValorInicial(data));
  const [quantidadeValor, setQuantidadeValor] = useState('');
  const [editarQuantidade, setEditarQuantidade] = useState(null);

  const handleEditQuantidade = (event, item) => {
    event.stopPropagation();
    setEditarQuantidade(item);
    setQuantidadeValor(item.quantidadeMinima.toFixed(2).replace('.', ','));
  };

  const handleQuantityChange = useCallback(event => {
    const newValue = event.target.value.replace(',', '.');
    setQuantidadeValor(newValue);
  }, []);

  const calcularDescontoMultiplo = useCallback((quantidade, regra) => {
    if (!regra.descontoMultiplo) {
      return 1;
    }
    const quantidadeMinimaOriginal = regra.quantidadeMinimaOriginal || regra.quantidadeMinima;
    const fatorMultiplicador = Math.floor(quantidade / quantidadeMinimaOriginal);
    return fatorMultiplicador > 1 ? fatorMultiplicador : 1;
  }, []);

  const calculaTotais = (newQuantity, item) => {
    const newTotalBruto = newQuantity * item.precoVenda;

    if (item.type === 'regra') {
      if (item?.porcentagemDescontoRegra !== null && item.porcentagemDescontoRegra > 0) {
        const descontoPercentual =
          newQuantity >= item.quantidadeMinimaOriginal ? item.porcentagemDescontoRegra || 0 : 0;
        const newTotalLiquido = newTotalBruto * (1 - descontoPercentual / 100);

        return {
          valorBrutoQntdMinima: newTotalBruto,
          valorTotalQntdMinima: newTotalLiquido
        };
      }
      const descontoValor = newQuantity >= item.quantidadeMinimaOriginal ? item.desconto : 0;
      const descontoPercentual =
        newQuantity >= item.quantidadeMinimaOriginal ? item.porcentagemDescontoRegra || 0 : 0;
      const fatorDesconto = calcularDescontoMultiplo(newQuantity, item);
      const descontoAplicado = descontoValor * fatorDesconto;
      const precoComDesconto = newTotalBruto - descontoAplicado;
      const newTotalLiquido = precoComDesconto * (1 - descontoPercentual / 100);

      return {
        valorBrutoQntdMinima: newTotalBruto,
        valorTotalQntdMinima: newTotalLiquido,
        descontoAplicado,
        porcentagemDescontoAplicado: (item.porcentagemDescontoRegra || 0) * fatorDesconto
      };
    } else if (item.type === 'tabela') {
      const newTotalLiquido = newTotalBruto * (1 + item.precoAjuste / 100);

      return {
        valorBruto: newTotalBruto,
        valorLiquido: newTotalLiquido
      };
    }
  };

  const updateProdutoRegras = (prevProduto, editarQuantidade, newQuantity, totals) => {
    let updatedEditarQuantidade = null;
    const updatedProdutoRegras = prevProduto.produtoRegras.map(regra => {
      if (regra === editarQuantidade) {
        const updatedRegra = {
          ...regra,
          quantidadeMinima: newQuantity,
          ...totals
        };
        updatedEditarQuantidade = updatedRegra;
        return updatedRegra;
      } else {
        return regra;
      }
    });
    return {
      updatedProduto: { ...prevProduto, produtoRegras: updatedProdutoRegras },
      updatedEditarQuantidade
    };
  };

  const updateTabelaRegraProduto = (prevProduto, editarQuantidade, newQuantity, totals) => {
    let updatedEditarQuantidade = null;
    const updatedTabelaRegraProduto = prevProduto.tabelaRegraProduto.map(tabela => {
      if (tabela === editarQuantidade) {
        const updatedTabela = {
          ...tabela,
          quantidadeMinima: newQuantity,
          ...totals
        };
        updatedEditarQuantidade = updatedTabela;
        return updatedTabela;
      } else {
        return tabela;
      }
    });

    return {
      updatedProduto: { ...prevProduto, tabelaRegraProduto: updatedTabelaRegraProduto },
      updatedEditarQuantidade
    };
  };

  const handleQuantityBlur = useCallback(() => {
    if (editarQuantidade) {
      let newQuantity = parseFloat(quantidadeValor.replace(',', '.'));
      if (!isNaN(newQuantity) && newQuantity >= 0) {
        const originalQuantidade = newQuantity;
        const embalagemMultiplo = produto?.embalagemMultiplo;
        const embalagemQuantidade = produto?.embalagemQuantidade;

        if (embalagemMultiplo === true && embalagemQuantidade > 0) {
          const resto = newQuantity % embalagemQuantidade;
          if (resto > 0) {
            newQuantity = newQuantity + (embalagemQuantidade - resto);
          }
          newQuantity = parseFloat(newQuantity.toFixed(2));

          if (newQuantity !== originalQuantidade) {
            toast.produtoMulti(
              `<b>${produto?.codigo} - ${produto?.descricao}</b> é múltiplo de embalagem ${produto?.embalagemQuantidade}, 
              e a quantidade vendida foi alterada de ${originalQuantidade} para ${newQuantity}`
            );
            setQuantidadeValor(newQuantity.toString().replace('.', ','));
          }
        }
        const totals = calculaTotais(newQuantity, editarQuantidade);

        setProduto(prevProduto => {
          let result;
          if (editarQuantidade.type === 'regra') {
            result = updateProdutoRegras(prevProduto, editarQuantidade, newQuantity, totals);
          } else {
            result = updateTabelaRegraProduto(prevProduto, editarQuantidade, newQuantity, totals);
          }

          const { updatedProduto } = result;

          return updatedProduto;
        });
      }
    }
    setEditarQuantidade(null);
  }, [
    quantidadeValor,
    editarQuantidade,
    produto,
    calculaTotais,
    updateProdutoRegras,
    updateTabelaRegraProduto,
    setQuantidadeValor,
    toast
  ]);

  let regraDescontoValor = 0;
  let regraDescontoPercentual = 0;

  for (const regra of produto?.fat_regradesconto_produto || []) {
    if (regraDescontoValor === 0) {
      regraDescontoValor = regra.descontoValor;
    }
    if (regraDescontoPercentual === 0) {
      regraDescontoPercentual = regra.descontoPercentual;
    }
    if (regraDescontoValor !== 0 && regraDescontoPercentual !== 0) {
      break;
    }
  }

  let precoVendaComDesconto = produto?.estProdutoImposto[0]?.precoVenda;

  if (regraDescontoValor > 0) {
    precoVendaComDesconto -= regraDescontoValor;
  } else if (regraDescontoPercentual > 0) {
    precoVendaComDesconto -= precoVendaComDesconto * (regraDescontoPercentual / 100);
  }

  const handleProdutoClick = produtoSelecionado => {
    callback(produtoSelecionado);
  };

  const handleEnterKeyPress = useCallback(() => {
    if (editarQuantidade) {
      let newQuantity = parseFloat(quantidadeValor.replace(',', '.'));
      if (!isNaN(newQuantity) && newQuantity >= 0) {
        const originalQuantidade = newQuantity;
        const embalagemMultiplo = produto?.embalagemMultiplo;
        const embalagemQuantidade = produto?.embalagemQuantidade;
  
        if (embalagemMultiplo === true && embalagemQuantidade > 0) {
          const resto = newQuantity % embalagemQuantidade;
          if (resto > 0) {
            newQuantity = newQuantity + (embalagemQuantidade - resto);
          }
          newQuantity = parseFloat(newQuantity.toFixed(2));
  
          if (newQuantity !== originalQuantidade) {
            toast.produtoMulti(
              `<b>${produto?.codigo} - ${produto?.descricao}</b> é múltiplo de embalagem ${produto?.embalagemQuantidade}, 
              e a quantidade vendida foi alterada de ${originalQuantidade} para ${newQuantity}`
            );
            setQuantidadeValor(newQuantity.toString().replace('.', ','));
          }
        }
        const totals = calculaTotais(newQuantity, editarQuantidade);

        setProduto(prevProduto => {
          let result;
          if (editarQuantidade.type === 'regra') {
            result = updateProdutoRegras(prevProduto, editarQuantidade, newQuantity, totals);
          } else {
            result = updateTabelaRegraProduto(prevProduto, editarQuantidade, newQuantity, totals);
          }

          const { updatedProduto } = result;

          return updatedProduto;
        });

        setEditarQuantidade(null);
      }
    }
  }, [
    quantidadeValor,
    editarQuantidade,
    produto,
    calculaTotais,
    updateProdutoRegras,
    updateTabelaRegraProduto,
    setQuantidadeValor,
    toast
  ]);

  return (
    <>
      {produto?.fat_regradesconto_produto?.length > 0 &&
        produto?.produtoRegras.map((regra, index) => (
          <RegraItem
            key={index}
            produto={produto}
            regra={regra}
            editarQuantidade={editarQuantidade}
            quantidadeValor={quantidadeValor}
            handleQuantityChange={handleQuantityChange}
            handleQuantityBlur={(e) => handleQuantityBlur(e.target.value)}
            handleEnterKeyPress={handleEnterKeyPress}
            handleEditQuantidade={handleEditQuantidade}
            handleProdutoClick={handleProdutoClick}
            classes={classes}
          />
        ))}
      {produto?.fatTabelavendaProduto?.length > 0 &&
        produto?.tabelaRegraProduto.map((tabela, index) => (
          <TabelaItem
            key={index}
            produto={produto}
            tabela={tabela}
            editarQuantidade={editarQuantidade}
            quantidadeValor={quantidadeValor}
            handleQuantityChange={handleQuantityChange}
            handleQuantityBlur={(e) => handleQuantityBlur(e.target.value)}
            handleEnterKeyPress={handleEnterKeyPress}
            handleEditQuantidade={handleEditQuantidade}
            handleProdutoClick={handleProdutoClick}
            classes={classes}
          />
        ))}
    </>
  );
};

export default ListItemProduto;

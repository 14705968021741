import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import api from '~/services/api';
import toast from '~/services/toast';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';
import { API_NODE_URL } from '~/constants';

import { Header } from './styles';
import { CustomNumberInput } from '~/components/CustomNumberInput';
import { BASE_ITEM } from '~/store/modules/pdv/pdv-helper';
import currency from 'currency.js';
import { selectorRotina } from '~/store/modules/auth/auth-selectors';

const ModalEditarQuantidade = ({ open, onClose, data: { produto, itemVenda, quantidade } }) => {
  const dispatch = useDispatch();

  const quantidadeRef = useRef();
  const { venda } = useSelector(state => state.pdv);
  const [quantidadeAtualizada, setQuantidadeAtualizada] = useState(quantidade);

  const changeQuantidade = quantidade => {
    const produto = itemVenda?.produtoEstProduto;
    const embalagemMultiplo = produto?.embalagemMultiplo;
    const quantidadeRefValue = quantidadeRef?.current?.valueAsNumber;

    if (embalagemMultiplo === true) {
      handleEmbalagemMultiplo(quantidade);
    } else {
      handleDefault(quantidade);
    }
  };

  const handleEmbalagemMultiplo = e => {
    const produto = itemVenda?.produtoEstProduto;
    const quantidadeEmbalagem = produto?.embalagemQuantidade;
    const quantidadeRefValue = quantidadeRef?.current?.valueAsNumber;

    if (quantidadeEmbalagem > 0 && quantidadeRefValue !== 0) {
      const quantidadeAjustada =
        Math.ceil(quantidadeRefValue / quantidadeEmbalagem) * quantidadeEmbalagem;
      showToastIfDifferent(quantidadeRefValue, quantidadeAjustada);

      if (quantidadeAjustada) {
        setQuantidadeAtualizada(quantidadeAjustada);
      }
    }
  };

  const handleDefault = e => {
    const valorFormatado = e.target.value?.replace(',', '.');
    if (valorFormatado && valorFormatado !== '') {
      const value = parseFloat(valorFormatado);
      setQuantidadeAtualizada(value);
    }
    return valorFormatado;
  };

  const showToastIfDifferent = (originalQuantidade, novaQuantidade) => {
    if (originalQuantidade !== novaQuantidade) {
      const produto = itemVenda?.produtoEstProduto;
      toast.produtoMulti(`<b>${produto?.codigo} - ${produto?.descricao}</b> é múltiplo de embalagem ${produto?.embalagemQuantidade}, 
      e a quantidade vendida foi alterada de ${originalQuantidade} para ${novaQuantidade}`);
    }
  };

  const handleSubmit = async () => {
    dispatch(
      pdvActions.editarQuantidadeItemVendido({ ...itemVenda, quantidadeAtualizada }, () => {
        if (onClose) {
          onClose();
        }
      })
    );

    return;
    const toastInstance = toast.loading('Aguarde...');

    console.log({ quantidadeAtualizada });
    if (!quantidadeAtualizada) {
      return toast.warning('Por favor informe a quantidade do item');
    }

    // if (!produto?.fracionavel && !Number.isInteger(quantidadeAtualizada)) {
    //   return toast.warning('O produto não é fracionável');
    // }

    const { data } = await api.get(
      `${API_NODE_URL}/faturamento/documento-saida/produto-grade-venda?produto=${itemVenda?.produtoCodigo}`
    );

    const produtoAtualizado = data?.[0];
    const imposto = produtoAtualizado?.estProdutoImposto?.[0];

    const produtoPrecoVenda = imposto?.precoVenda ?? 0;
    const percentualAcrescimo = venda?.fatCondicaopagamento?.percentualAcrescimo ?? 0;

    const itemValorAcrescimoCondicao = percentualAcrescimo
      ? currency(produtoPrecoVenda).multiply(percentualAcrescimo)
      : 0;

    const itemValorUnitario =
      percentualAcrescimo > 0
        ? currency(produtoPrecoVenda).add(itemValorAcrescimoCondicao)
        : produtoPrecoVenda;

    const itemAtualizado = {
      uuid: uuid(),
      ...BASE_ITEM,
      produtoEstProduto: produtoAtualizado,
      produtoEstProdutoId: produtoAtualizado?.id,
      produtoEstProdutoGrade: produtoAtualizado?.estProdutoGrade ?? null,
      produtoEstProdutoGradeId:
        produtoAtualizado?.estProdutoGrade?.length > 0
          ? produtoAtualizado?.estProdutoGrade[0]?.id
          : null,
      produtoGradeLinha: produtoAtualizado?.estProdutoGrade?.gradeEixoLinha ?? '',
      produtoGradeColuna: produtoAtualizado?.estProdutoGrade?.gradeEixoColuna ?? '',
      produtoCodigo: produtoAtualizado?.codigo,
      produtoDescricao: produtoAtualizado?.descricao,
      produtoUnidadeComercial: produtoAtualizado?.unidadeComercial,
      produtoUnidadeTributavel: produtoAtualizado?.unidadeTributavel,
      produtoQuantidadeTributavel: produtoAtualizado?.quantidadeTributavel,
      produtoEanTributavel: produtoAtualizado?.eanTributavel,
      produtoQuantidadeEmbalagem: produtoAtualizado?.embalagemQuantidade,
      produtoPrecoVenda,
      produtoEstArmazem: imposto?.estoqueEstArmazem,
      produtoEstArmazemId: imposto?.estoqueEstArmazemId,
      produtoEan: produtoAtualizado?.ean,
      produtoCodigoAuxiliar: produtoAtualizado?.codigoAuxiliar,
      produtoOrigem: produtoAtualizado?.mercadoriaOrigem,
      estProdutoImposto: produtoAtualizado?.estProdutoImposto,
      itemCfop: venda?.fisOperacao ? venda?.fisOperacao?.itemCfop : produtoAtualizado?.cfopSaida,
      itemValorUnitario,
      itemValorAcrescimoCondicao,
      itemQuantidade: quantidadeAtualizada,
      itemTipo: produtoAtualizado?.itemTipo || null,
      itemEstoque: produtoAtualizado?.saldoEstoque?.estoqueReal || 0,
      estoqueReal: produtoAtualizado?.saldoEstoque?.estoqueReal || 0,
      itemValorTotal: imposto?.precoVenda,
      estoqueReservado: produtoAtualizado?.saldoEstoque?.estoqueReservado || 0,
      estoqueFuturo: produtoAtualizado?.saldoEstoque?.estoqueFuturo || 0,
      estoqueDisponivel: produtoAtualizado?.saldoEstoque?.estoqueReal
        ? produtoAtualizado?.saldoEstoque?.estoqueReal +
          produtoAtualizado?.saldoEstoque?.estoqueReservado * -1
        : 0,
      fracionavel: produtoAtualizado?.fracionavel,
      permiteCustomizar: imposto?.permiteCustomizar || false
    };

    dispatch(
      pdvActions.adicionarItem(itemAtualizado, () => {
        setTimeout(() => {
          dispatch(pdvActions.atualizarQuantidade(itemAtualizado, quantidadeAtualizada));

          dispatch(pdvActions.cancelarItem(itemVenda, false));
          toastInstance.close();

          if (onClose) {
            onClose();
          }
        }, 200);
      })
    );
  };

  return (
    <>
      <Mui.Dialog
        open={open}
        onKeyDown={key => {
          if (key.code === 'Escape') {
            onClose();
          }
        }}
        PaperComponent={Mui.Paper}
        maxWidth={'sm'}
      >
        <Mui.DialogTitle>
          <Mui.AppBar color="primary" position="static">
            <Mui.Toolbar>
              <Mui.Box style={{ minWidth: 180 }}>
                <Mui.Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                  Editar quantidade
                </Mui.Typography>
              </Mui.Box>
              <Mui.Box flexGrow={1} />

              <Mui.IconButton color="inherit" onClick={onClose} aria-label="close">
                <MuiIcons.Close />
              </Mui.IconButton>
            </Mui.Toolbar>
          </Mui.AppBar>

          <Mui.Grid item md={12}>
            <Header>
              <Mui.Box p={1}>
                <Mui.Typography variant="caption">Produto</Mui.Typography>
                <Mui.Typography variant="h4">
                  {produto?.codigo} {' - '}
                  {produto?.descricao}
                </Mui.Typography>
              </Mui.Box>
            </Header>
          </Mui.Grid>
        </Mui.DialogTitle>
        <Mui.DialogContent dividers style={{ padding: 16 }}>
          <Mui.Grid container spacing={2}>
            <Mui.Grid item md={6}>
              <Mui.Typography variant="p">Quantidade:</Mui.Typography>
              <Mui.TextField
                id="quantidade"
                type="number"
                variant="outlined"
                fullWidth
                autoFocus
                disabled={true}
                InputProps={{
                  style: {
                    height: 44
                  }
                }}
                value={quantidade ?? 1}
              />
            </Mui.Grid>
            <Mui.Grid item md={6}>
              <Mui.Typography variant="p">Quantidade atualizada:</Mui.Typography>
              {produto?.fracionavel ? (
                <Mui.TextField
                  id="quantidadeAtualizada"
                  inputRef={quantidadeRef}
                  variant="outlined"
                  required
                  type="numeric"
                  fullWidth
                  value={quantidadeAtualizada ?? 1}
                  onChange={handleDefault}
                  onBlur={changeQuantidade}
                  InputProps={{
                    style: {
                      height: 44
                    },
                    inputComponent: CustomNumberInput
                  }}
                />
              ) : (
                <Mui.TextField
                  id="quantidadeAtualizada"
                  inputRef={quantidadeRef}
                  type="number"
                  variant="outlined"
                  fullWidth
                  autoFocus
                  InputProps={{
                    style: {
                      height: 44
                    }
                  }}
                  value={quantidadeAtualizada ?? 1}
                  pattern="[0-9]*"
                  onKeyPress={ev => {
                    if (!itemVenda?.produtoEstProduto?.fracionavel && (ev.key === '.' || ev.key === ',')) {
                      ev.preventDefault();
                    }
                  }}
                  onChange={handleDefault}
                  onBlur={changeQuantidade}
                />
              )}
            </Mui.Grid>
          </Mui.Grid>
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button
            variant="contained"
            className="button-success"
            startIcon={<MuiIcons.CheckCircle />}
            onClick={handleSubmit}
          >
            Confirmar
          </Mui.Button>
          <Mui.Button startIcon={<MuiIcons.Close />} onClick={onClose}>
            Cancelar (ESC)
          </Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
    </>
  );
};

export default ModalEditarQuantidade;
